import { AuthService } from './../../../auth/service/auth.service';
import { Injectable } from '@angular/core';

import { Firestore, getDoc, doc, setDoc, addDoc, collection, collectionData, orderBy, query, deleteDoc } from '@angular/fire/firestore';
import { User } from '../../modal/user.interface';
import { Observable } from 'rxjs';
import { docData } from 'rxfire/firestore';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  user: any;

  constructor(private firestore: Firestore, private authServ: AuthService) { }

  async getUserLogged() {
    this.user = await this.authServ.getUser();
  }

  async saveUser(user: User) {
    await setDoc(doc(this.firestore, `usuarios/${this.user.uid}`), user, {
      merge: true,
    });
  }

  async saveUserEdit(user: User) {
    await setDoc(doc(this.firestore, `usuarios/${user.uid}`), user, {
      merge: true,
    });
  }

  getUserObservable(): Observable<User> {
    return docData(doc(this.firestore, `usuarios/${this.user?.uid}`), {
      idField: 'uid',
    });
  }

  getUserObservableList(): Observable<any> {
    let c = collection(this.firestore, `usuarios`);
    let q = query(c);

    return collectionData(q, { idField: 'uid' }) ;
  }

  getUserByIdObservable(uid: string): Observable<User> {
    return docData(doc(this.firestore, `usuarios/${uid}`), {
      idField: 'uid',
    });
  }

  async newSecondUser(user: User, uid: string): Promise<string> {
    let data = await addDoc(collection(this.firestore, `usuarios/${uid}/users`), user);
    return data.id;
  }

  async setSecondUser(user: User, uid: string) {
    await setDoc(doc(this.firestore, `usuarios/${uid}/users/${user.uid!}`), user);
  }

  getListSecondUserObservable(uid: string): Observable<User[]> {
    let c = collection(this.firestore, `usuarios/${uid}/users`);
    let q = query(c, orderBy('cnpj','asc'));
    return collectionData(q, {
      idField: 'uid',
    });
  }

  async getListSecondUser(uid: any) {
    return collectionData(collection(this.firestore, `usuarios/${uid}/users`));
  }

  controlScript(): Observable<any> {
    return docData(doc(this.firestore, `controle/scriptOldCart`), {
      idField: 'uid',
    });
  }

  async setControlScript(script: any) {
    await setDoc(doc(this.firestore, `controle/scriptOldCart`), script);
  }

  async deleteCNPJ(uid: string, uidDelete: string){
    return deleteDoc(doc(this.firestore, `usuarios/${uid}/users/${uidDelete}`));
  }
  
  async listUser() {
  }
}
