import { Injectable } from '@angular/core';
import {
  Auth,
  signInWithEmailAndPassword,
  signInWithPopup,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signOut,
  user,
  sendPasswordResetEmail,
  updatePassword,
  User as UserFirebase,
  reauthenticateWithCredential,
} from '@angular/fire/auth';
import { EmailAuthProvider } from "firebase/auth";

import { Firestore, getDoc, doc, setDoc, docData } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { User } from 'src/app/shared/modal/user.interface';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MessageService } from 'primeng/api';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user?: any;

  constructor(
    public auth: Auth,
    private firestore: Firestore,
    private router: Router,
    private messageService: MessageService,
    public authOld: AngularFireAuth,
    private http: HttpClient
  ) { }

  async getUser() {
    return new Promise(resolve => {
      user(this.auth).subscribe((resp) => {
        resolve(resp)
      })
    })
  }

  async login(login: { email: string; password: string }) {
    await signInWithEmailAndPassword(this.auth, login.email.replace(/\s/g, ""), login.password).then(() => {
      this.router.navigate(['menplast']);
    });
  }

  async loginWithGoogle() {
    await signInWithPopup(this.auth, new GoogleAuthProvider()).then((resp) => {
      let userGoogle = resp.user;
      // admin.auth().setCustomUserClaims(resp.user!.uid!, permission);
      getDoc<User>(doc(this.firestore, `usuarios/${userGoogle!.uid}`)).then(
        (res) => {
          console.log(res.data());
          if (!res.data()) {
            let userUpdate: User;
            userUpdate = {
              dateCreate: Date.now(),
              email: userGoogle!.email!,
              displayName: userGoogle!.displayName!,
              phoneNumber: userGoogle!.phoneNumber!,
              photoURL: userGoogle!.photoURL!,
              active: true,
            };
            setDoc(
              doc(this.firestore, `usuarios/${userGoogle!.uid}`),
              userUpdate
            ).then(() => {
              alert('Cadastre o seu local para ter acesso aos produtos!')
              this.router.navigate(['menplast/user']);
            });
          } else {
            this.router.navigate(['menplast']);
          }
        }
      );
    }).catch((error) => { console.log(error) })
  }

  async register(user: User) {
    await createUserWithEmailAndPassword(
      this.auth,
      user.email!.replace(/\s/g, ""),
      user.password!
    ).then((resp) => {
      setDoc(
        doc(this.firestore, `usuarios/${resp.user.uid}`),
        user
      );
      this.sendEmail(user)
    }).then(() => {
      this.router.navigate(['menplast']);
    });
  }

  logout() {
    signOut(this.auth).then(() => { this.router.navigate(['auth']) });
  }

  async sendPasswordResetEmail(email: string) {
    await sendPasswordResetEmail(this.auth, email.replace(/\s/g, ""));
  }

  async updatePassword(currPwd: string, newPassword: string, newPasswordConfirm: string) {
    const credential = EmailAuthProvider.credential(this.auth.currentUser!.email!, currPwd);
    await reauthenticateWithCredential(this.auth.currentUser!, credential).then(() => {

      if (newPassword != newPasswordConfirm) {
        throw new Error('A nova senha não é igual ao campo "Confirmar senha".');
      } else {
        updatePassword(this.auth.currentUser!, newPassword).then(() => {
        });
      }
    });
  }

  async sendEmail(user: User) {

    let text = `Novo cadastro de usuário no sistema de ATACADO. <br><br>`;
    text += `Nome: ${user.displayName}<br>`;
    text += `CNPJ/CPF: ${user.cnpj}<br>`;
    text += `IE/RG: ${user.inscricao}<br><br>`;

    text += `E-mail: ${user.email}<br>`;
    text += `Telefone 1: ${user.phoneNumber ? user.phoneNumber : ' - '}<br>`;
    text += `Telefone 1: ${user.phoneNumber2 ? user.phoneNumber2 : ' - '}<br><br>`;

    text += `CEP: ${user.cep}<br>`;
    text += `Cidade/UF: ${user.cidade}/${user.estado}<br>`;
    text += `Endereço: ${user.logradouro}, ${user.numero}, ${user.complemento} - ${user.bairro}<br>`;

    let mailList = `daniel.huebes@gmail.com`;
    if (window.location.href.indexOf('localhost') == -1) {
      mailList = `comercial@menplast.com.br`;
    }

    let url = "https://gp6efon4pi.execute-api.us-east-1.amazonaws.com/default/genericEmail";
    let body = {
      from: `atacado@menplast.com.br`,
      email: mailList,
      message: text,
      subject: `Novo cadastro de usuário`
    }

    const headers = new HttpHeaders().set(
      "Content-Type",
      "application/json"
    );
    let options = { headers }
    await this.http.post(url, body, options).toPromise()
      .then(() => {
      })
      .catch((error) => {
        alert("ERRO!");
      });
  }
}
