import { AuthService } from './../../../auth/service/auth.service';
import { Injectable } from '@angular/core';
import { Item } from '../../modal/item.interface';
import {
  Firestore,
  getDoc,
  doc,
  setDoc,
  collectionData,
  query,
  orderBy,
  getDocs,
  addDoc,
  deleteDoc, collection, updateDoc, where, docData, limit, startAfter, startAt
} from '@angular/fire/firestore';
import { User } from '../../modal/user.interface';
import { Order } from '../../modal/order.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class SellService {

  constructor(private firestore: Firestore,
    private authServ: AuthService,
    private http: HttpClient) { }

  colCartData = collection(this.firestore, `cart`);
  colOrder = collection(this.firestore, `order`);
  colItems = collection(this.firestore, 'items');

  getColCart() {
    console.log(this.authServ.auth.currentUser?.uid);

    return collection(
      this.firestore,
      `cart/${this.authServ.auth.currentUser?.uid}/items`
    );
  }

  getColCartKit() {
    return collection(
      this.firestore,
      `cart/${this.authServ.auth.currentUser?.uid}/items_kit`
    );
  }

  getColCartUser(user:any) {
    console.log(user.uid);

    return collection(
      this.firestore,
      `cart/${user.uid}/items`
    );
  }

  getColCartKitUser(user:any) {
    return collection(
      this.firestore,
      `cart/${user.uid}/items_kit`
    );
  }

  saveItem() { }

  getItems(/*offset: number = 0*/) {
    /*
    
    let c =  collection(
      this.firestore,
      `items`
    );
    let q = query(c, orderBy('ficha', 'asc'), limit(3), startAfter(offset));
    return collectionData<Item>(q, { idField: 'uid' });
      */

    // let q = query(this.colItems,orderBy('regiao.'+61,'asc'), orderBy('descricao', 'asc'));
    // let q = query(this.colItems,orderBy('regiao.'+user.regiao,'asc'));
    return collectionData<Item>(this.colItems, { idField: 'uid' });
  }

  async getItemById(id: any) {

    return await getDoc(doc(this.firestore, `items/${id}`))
  }

  async saveCartData(observationPed: string, seller: string) {
    let cartDescrip = {
      lastDateAdd: Date.now(),
      observationPed: observationPed,
      seller: seller
    };
    
    if (this.authServ.auth.currentUser) {
      await setDoc(
        doc(
          this.firestore,
          `cart/${this.authServ.auth.currentUser?.uid}`
        ),
        cartDescrip
      );
    } else {
      localStorage.setItem(`cart-data`, JSON.stringify(cartDescrip));

    }
  }

  async saveCart(item: Item, kit: boolean, amount: number = 1, observationPed: string, seller: string) {
    let cartDescrip;
    if (observationPed || seller) {
      cartDescrip = {
        lastDateAdd: Date.now(),
        observationPed: observationPed,
        seller: seller ? seller : ''
      };
      if (this.authServ.auth.currentUser) {
        await setDoc(
          doc(
            this.firestore,
            `cart/${this.authServ.auth.currentUser?.uid}`
          ),
          cartDescrip
        );
      } else {
        localStorage.setItem(`cart-data`, JSON.stringify(cartDescrip));
      }
    }
    if (kit) {
      item.amount = amount * item.quantidade!;
    } else {
      item.amount = amount;
    }
    item.kit = kit;
    if (!(item as any).addedAt) {
      (item as any).addedAt = Date.now();
    }
    if (!item.corSelecionada) {
      delete item.corSelecionada;
    }
    if (this.authServ.auth.currentUser) {
      await setDoc(
        doc(
          this.firestore,
          `cart/${this.authServ.auth.currentUser?.uid}/items${kit ? '_kit' : ''}/${item.uid}`
        ),
        item
      ).catch(error => console.log(error));
    } else {
      let aux = localStorage.getItem(`atacado-menplast-cart${kit ? '-kit' : ''}`);      
      let cartStorage = aux ? JSON.parse(aux) : [];

      let found = false;
      for (let i = 0; i < cartStorage.length; i++) {
        const element = cartStorage[i];
        
        if (element.codigo == item.codigo && JSON.stringify(element.cores)  == JSON.stringify(item.cores)) {
          element.amount = item.amount;
          found = true;
        }
      }

      if (!found) {
        cartStorage.push(item);
      }
      localStorage.setItem(`atacado-menplast-cart${kit ? '-kit' : ''}`, JSON.stringify(cartStorage));
    }
  }

  async updateItemAmountCart(items: Item[], kit: boolean) {
    items.forEach((item) => {
      updateDoc(
        doc(
          this.firestore,
          `cart/${this.authServ.auth.currentUser?.uid}/items${kit ? '_kit' : ''}/${item.uid}`
        ),
        { amount: item.amount }
      );
    });
  }

  getCartList(): Observable<any> {
    let c = collection(this.firestore, `cart`);
    let q = query(c);

    return collectionData(q, { idField: 'uid' }) ;
  }

  getCart() {
    let q = query(this.getColCart(), orderBy('descricao', 'asc'));
    return collectionData<Item>(q, { idField: 'uid' });
  }

  getCartKit() {
    let q = query(this.getColCartKit(), orderBy('descricao', 'asc'));
    return collectionData<Item>(q, { idField: 'uid' });
  }

  getCartUser(user:any) {
    let q = query(this.getColCartUser(user), orderBy('descricao', 'asc'));
    return collectionData<Item>(q, { idField: 'uid' });
  }

  getCartKitUser(user:any) {
    let q = query(this.getColCartKitUser(user), orderBy('descricao', 'asc'));
    return collectionData<Item>(q, { idField: 'uid' });
  }

  async deleteItemCart(item: Item) {
    await deleteDoc(
      doc(
        this.firestore,
        `cart/${this.authServ.auth.currentUser?.uid}/items${item.kit ? '_kit' : ''}/${item.uid}`
      )
    );
  }

  async deleteUserCartScript(uid:any) {
    await deleteDoc(
      doc(
        this.firestore,
        `cart/${uid}`
      )
    )
  }

  async deleteUserCart() {
    let sub = this.getCartKit().subscribe(async (resp) => {
      for (let index = 0; index < resp.length; index++) {
        const item = resp[index];
        await deleteDoc(
          doc(
            this.firestore,
            `cart/${this.authServ.auth.currentUser?.uid}/items_kit/${item.uid}`
          )
        )
      }
      sub.unsubscribe();
    })
    let sub2 = await this.getCart().subscribe(async (resp) => {
      for (let index = 0; index < resp.length; index++) {
        const item = resp[index];
        await deleteDoc(
          doc(
            this.firestore,
            `cart/${this.authServ.auth.currentUser?.uid}/items/${item.uid}`
          )
        )
      }
      sub2.unsubscribe();
    })
  }

  async deleteOrder(order: Order) {
    if (order.uid) {
      await deleteDoc(
        doc(
          this.firestore,
          `order/${order.uid}`
        )
      );
    }

  }

  async saveOrder(totalPrice: number, totalUnits: number, observationPedido: string, metodoPayment: string, region: string, paymentDay: string, installment: number, seller: string, user: User, desconto: number, descontoDesc: string, valorFrete: number) {
    let data = (await getDoc(doc(this.firestore, `code/code`))).data()
    let code = 1;
    data!.code ? code = data!.code! + 1 : '';
    await setDoc(doc(this.firestore, `code/code`), { code });
    let order = {
      code: code,
      dateCreate: Date.now(),
      uidUserOrdered: this.authServ.auth.currentUser?.uid,
      nameUserOrdered: user?.displayName,
      phoneUserOrdered: user?.phoneNumber,
      totalPrice: totalPrice,
      totalUnits: totalUnits,
      observation: observationPedido ? observationPedido : '',
      seller: seller,
      paymentMethod: metodoPayment,
      region: region,
      paymentDay: paymentDay,
      installment: installment,
      user: user,
      desconto: desconto,
      descontoDesc: descontoDesc,
      valorFrete: valorFrete,
      status: {name: 'Novo', code: 'novo'}
    };
    return { uidOrder: (await addDoc(this.colOrder, order)).id, code: code, data: order };
  }

  async saveItemsOrder(uidOrder: string, items: Item[]) {
    for (let index = 0; index < items.length; index++) {
      const item = items[index];
      await setDoc(
        doc(this.firestore, `order/${uidOrder}/items${item.kit ? '_kit' : ''}/${item.uid}`),
        item
      );
    }
  }

  updateOrder(uid: string, object: any) {
    let d = doc(this.firestore, `order/${uid}`)
    return updateDoc(d, object);
  }

  getOrders(user: User) {
    let q = query(this.colOrder, where('uidUserOrdered', '==', user.uid), orderBy('dateCreate', 'desc'));
    if (user.email == 'dev@dgsys.com.br' || user.email == 'eukryss@gmail.com' || user.email == 'andi_07@hotmail.com') {
      q = query(this.colOrder, orderBy('dateCreate', 'desc'));
    }
    return collectionData<Order>(q, { idField: 'uid' });
  }

  async getOrder(user: User) {

    return await getDoc(doc(this.firestore, `cart/${user.uid}`))

  }

  getItemsOrder(uidOrder: string) {
    let c = collection(
      this.firestore,
      `order/${uidOrder}/items`
    );
    let q = query(c, orderBy('descricao', 'asc'));
    return collectionData<Item>(q, { idField: 'uid' });
  }

  getItemsFromOrder(order: Order) {
    let c = collection(
      this.firestore,
      `order/${order.uid}/items`
    );
    let q = query(c, orderBy('descricao', 'desc'));
    return collectionData<Order>(q, { idField: 'uid' });
  }

  getKitItemsOrder(uidOrder: string) {
    let c = collection(
      this.firestore,
      `order/${uidOrder}/items_kit`
    );
    let q = query(c, orderBy('descricao', 'asc'));
    return collectionData<Item>(q, { idField: 'uid' });
  }

  getKitItemsFromOrder(order: Order) {
    let c = collection(
      this.firestore,
      `order/${order.uid}/items_kit`
    );
    let q = query(c, orderBy('descricao', 'desc'));
    return collectionData<Order>(q, { idField: 'uid' });
  }

  async sendEmail(code: number, customerName: string, orderUrl: string) {

    let text = `Novo pedido feito pelo portal de ATACADO. <br><br>`;
    text += `Código: ${code}<br>`;
    text += `Cliente: ${customerName}<br>`;
    text += `URL: ${orderUrl}<br>`;

    let mailList = `daniel.huebes@gmail.com`;
    if (window.location.href.indexOf('localhost') == -1) {
      mailList = `comercial@menplast.com.br`;
    }

    let url = "https://gp6efon4pi.execute-api.us-east-1.amazonaws.com/default/genericEmail";
    let body = {
      from: `atacado@menplast.com.br`,
      email: mailList,
      message: text,
      subject: `Novo pedido - ${code}`
    }

    const headers = new HttpHeaders().set(
      "Content-Type",
      "application/json"
    );
    let options = { headers }
    await this.http.post(url, body, options).toPromise()
      .then(() => {
      })
      .catch((error) => {
        alert("ERRO!");
      });
  }

  async sendErrorEmail(error: any) {

    let text = `Erro ao finalizar pedido no portal de ATACADO. <br><br>`;
    text += `Erro: <br>`;
    text += `${error}`;

    let mailList = `daniel.huebes@gmail.com`;
    let url = "https://gp6efon4pi.execute-api.us-east-1.amazonaws.com/default/genericEmail";
    let body = {
      from: `no.reply.dgsys@gmail.com`,
      email: mailList,
      message: text,
      subject: `Erro ao finalizar pedido no portal de ATACADO`
    }

    const headers = new HttpHeaders().set(
      "Content-Type",
      "application/json"
    );
    let options = { headers }
    await this.http.post(url, body, options).toPromise()
      .then(() => {
      })
      .catch((error) => {
        alert("ERRO!");
      });
  }

  getPriceValid(item: any, isPromo: boolean) {
    if (item) {
      if (item?.dataPromo && isPromo) {
        let dateValid = moment.unix(item?.dataPromo?.seconds).isSameOrAfter(moment());
        if (item?.precoPromo && dateValid) 
          return item.precoPromo;
      }
      return item.preco;
    }
    return null
  }
}
