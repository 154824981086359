import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { AppInitService } from './shared/services/app-init/app-init.service';
import { AuthService } from './auth/service/auth.service';
import { UserService } from './shared/services/user/user.service';
import * as moment from 'moment';
import { SellService } from './shared/services/sell/sell.service';
import { UtilsService } from './shared/services/utils/utils.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MessageService, UtilsService, UserService]

})
export class AppComponent implements OnInit {
  title = 'menplast';
  whatsAppUrl = '';
  constructor(private appInitServ: AppInitService,
              private sellService: SellService,
              private userService: UserService,
              private authServ: AuthService) { }
  

  randomIntFromInterval(min: number, max: number) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
  }
  async ngOnInit() {
    this.appInitServ.getItems();
    this.userService.controlScript().subscribe((tm) => {
      //console.log(moment().diff(moment(tm.date, 'DD/MM/yyyy'), 'days'));
      
      if (moment().diff(moment(tm.date, 'DD/MM/yyyy'), 'days') > 0) {
        this.userService.setControlScript({date: moment().format('DD/MM/yyyy')})
        this.deleteOldCarts();
      }
    });
    let number = '554733949515';
    this.authServ.getUser().then((user) => {
      let msg = '';
      if (user) {
        msg = 'Olá, estou montando meu pedido de ATACADO e gostaria de falar com um atendente.';
      } else {
        msg = 'Olá, gostaria de saber mais sobre como comprar no ATACADO.';
      }
      this.whatsAppUrl = `https://api.whatsapp.com/send?phone=${number}&lang=pt_br&text=${msg}`;
    })
  }

  deleteOldCarts() {
    let sub = this.sellService.getCartList().subscribe(async(tm) =>{
      console.log('length: ', tm.length);
      let count = 0;
      let countDel = 0;
      let promise:any[] = [];

      tm.forEach(async(cart:any) => {
        //console.log(cart);
        if (cart.lastDateAdd) {
          if (moment().diff(moment(cart.lastDateAdd), 'months') > 5) {
            promise.push(this.sellService.deleteUserCartScript(cart.uid));
            // console.log('exclui');
            countDel++;
          } else {
            // console.log(moment(cart.lastDateAdd));
            // console.log('dentro');
            count++;
          }
        } else {
          promise.push(this.sellService.deleteUserCartScript(cart.uid));
          // console.log('exclui');
          countDel++;
        }
      });
      console.log('exclui: ', countDel );
      console.log('dentro: ', count );
      await Promise.all(promise)
    },(err) => {
      console.log(err);
    },() => {
      sub.unsubscribe();
    });
  }
}
