import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CardModule } from 'primeng/card';
import { InputTextModule } from 'primeng/inputtext';
import { InputMaskModule } from 'primeng/inputmask';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { InputNumberModule } from 'primeng/inputnumber';
import { BadgeModule } from 'primeng/badge';
import { ToastModule } from 'primeng/toast';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DialogModule } from 'primeng/dialog';
import { TabViewModule } from 'primeng/tabview';
import { RadioButtonModule } from 'primeng/radiobutton';
import { HttpClientModule } from '@angular/common/http';
import { TooltipModule } from 'primeng/tooltip';
import { CarouselModule } from 'primeng/carousel';
import { CheckboxModule } from 'primeng/checkbox';
import { TableModule } from 'primeng/table';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CardModule,
    InputTextModule,
    InputMaskModule,
    ButtonModule,
    DividerModule,
    InputNumberModule,
    BadgeModule,
    ToastModule,
    InputTextareaModule,
    DialogModule,
    TabViewModule,
    RadioButtonModule,
    HttpClientModule,
    TooltipModule,
    CarouselModule,
    CheckboxModule,
    TableModule,
    ConfirmDialogModule,
    DropdownModule,
    MultiSelectModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CardModule,
    InputTextModule,
    InputMaskModule,
    ButtonModule,
    DividerModule,
    InputNumberModule,
    BadgeModule,
    ToastModule,
    InputTextareaModule,
    DialogModule,
    TabViewModule,
    RadioButtonModule,
    TooltipModule,
    CarouselModule,
    CheckboxModule,
    TableModule,
    ConfirmDialogModule,
    DropdownModule,
    MultiSelectModule,
  ],
})
export class SharedModule {}
