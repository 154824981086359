import { SellService } from './../sell/sell.service';
import { Firestore } from '@angular/fire/firestore';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Item } from '../../modal/item.interface';
import { AuthService } from 'src/app/auth/service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AppInitService {

  protected bsItems?: BehaviorSubject<Item[]>;
  items$?: Observable<Item[]>;

  constructor(private fstore: Firestore, private sellServ: SellService) {
    this.bsItems = new BehaviorSubject<Item[]>([]);
    this.items$ = this.bsItems.asObservable();
   }

   getItems(){
    return new Promise(resolve => {
      this.sellServ.getItems().subscribe(resp => {
        this.bsItems!.next(resp);
        resolve(resp);
      })
    })
   }

   randomIntFromInterval(min: number, max: number) { // min and max included 
     return Math.floor(Math.random() * (max - min + 1) + min)
   }
 
   getRandomWhatsAppUrl() {
     let number = '554733949515';
     let msg = 'Olá, estou no portal do atacado, posso falar com um atendente?';
     
     return `https://api.whatsapp.com/send?phone=${number}&lang=pt_br&text=${msg}`;
   }
}
