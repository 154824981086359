import { Injectable, NgModule } from '@angular/core';
import { AngularFireAuthGuard, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/compat/auth-guard';
import { ActivatedRouteSnapshot, Resolve, RouterModule, Routes } from '@angular/router';
import { LinkTableService } from './shared/services/link-document-table/link-table.service';
import { take, tap } from 'rxjs/operators';
import { LinkDocumentTable } from './shared/models/link-document-table.model';
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['auth']);
const redirectLoggedInToItems = () => redirectLoggedInTo(['menplast']);

@Injectable({ providedIn: 'root' })
export class ResolveSpecialDocuments implements Resolve<any> {
  constructor(private service: LinkTableService) { }

  resolve(
    route: ActivatedRouteSnapshot
  ) {
    return this.service.getData(this.service.documentCollectionName).pipe(
      take(1),
      tap(res => {
        this.service.setSpecialDocuments = res as LinkDocumentTable[];
      }))
  }
}


const routes: Routes = [
  { path: '',   redirectTo: 'auth', pathMatch: 'full' },
  {
    path:'auth',
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToItems },
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule)
  },
  {
    path:'menplast',
    //canActivate: [AngularFireAuthGuard],
    //data: { authGuardPipe: redirectUnauthorizedToLogin },
    resolve: [ResolveSpecialDocuments],
    loadChildren: () => import('./view/view.module').then((m) => m.ViewModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
