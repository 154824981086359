<!--
<div style="background-color: #ffffff; color: rgb(214, 0, 0); width: 100%; text-align: center; margin-top: -15px; padding-top: 20px; padding-bottom: 20px;">
    <b style="padding-top: 15px;">FÉRIAS COLETIVAS entre 20/12/23 a 08/01/24 - PLANTÃO DE VENDAS (47) 3394-9515 com Angélica</b>
</div>
-->
<router-outlet></router-outlet>
<p-toast position="bottom-center" [style]="{'max-width': '80%'}"></p-toast>


<a class="whats-app" [href]="whatsAppUrl" target="_blank">
    <i class="fab fa-whatsapp my-float"></i>
</a>