import { Injectable } from '@angular/core';

import { Firestore, getDoc, doc, setDoc, addDoc, collection, collectionData, orderBy, query, deleteDoc } from '@angular/fire/firestore';
import { User } from '../../modal/user.interface';
import { Observable } from 'rxjs';
import { docData } from 'rxfire/firestore';
import { UtilsService } from '../utils/utils.service';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  user: any;

  constructor(private firestore: Firestore,
              private utilsService: UtilsService,
              private messageService: MessageService) { }

  async saveUser(user: User, merge: boolean, showError: boolean): Promise<any> {
    user.estado = user.estado.toUpperCase();
    user.cidade = user.cidade.toUpperCase();
    user.estadoEntrega = user.estadoEntrega ? user.estadoEntrega.toUpperCase() : '';
    user.cidadeEntrega = user.cidadeEntrega ? user.cidadeEntrega.toUpperCase() : '';
    await this.validateIBGECode(user, showError);
    await setDoc(doc(this.firestore, `usuarios/${user.uid}`), user, {
      merge: merge,
    });  
  }

  async saveUserEdit(user: User) {
    await setDoc(doc(this.firestore, `usuarios/${user.uid}`), user, {
      merge: true,
    });
  }

  getUserObservableList(): Observable<any> {
    let c = collection(this.firestore, `usuarios`);
    let q = query(c);

    return collectionData(q, { idField: 'uid' }) ;
  }

  getUserByIdObservable(uid: string): Observable<User> {
    return docData(doc(this.firestore, `usuarios/${uid}`), {
      idField: 'uid',
    });
  }

  async newSecondUser(user: User, uid: string): Promise<string> {
    await this.validateIBGECode(user, true);
    let data = await addDoc(collection(this.firestore, `usuarios/${uid}/users`), user);
    return data.id; 
  }

  async setSecondUser(user: User, uid: string) {
    await setDoc(doc(this.firestore, `usuarios/${uid}/users/${user.uid!}`), user);
  }

  getListSecondUserObservable(uid: string): Observable<User[]> {
    let c = collection(this.firestore, `usuarios/${uid}/users`);
    let q = query(c, orderBy('cnpj','asc'));
    return collectionData(q, {
      idField: 'uid',
    });
  }

  async getListSecondUser(uid: any) {
    return collectionData(collection(this.firestore, `usuarios/${uid}/users`));
  }

  controlScript(): Observable<any> {
    return docData(doc(this.firestore, `controle/scriptOldCart`), {
      idField: 'uid',
    });
  }

  async setControlScript(script: any) {
    await setDoc(doc(this.firestore, `controle/scriptOldCart`), script);
  }

  async deleteCNPJ(uid: string, uidDelete: string){
    return deleteDoc(doc(this.firestore, `usuarios/${uid}/users/${uidDelete}`));
  }
  
  async listUser() {
  }

  async validateIBGECode(user: User, showError: boolean): Promise<any> {
    if (!user.codIbge) {
      const estadoFinal = user.estadoEntrega ? user.estadoEntrega : user.estado;
      const cidadeFinal = user.cidadeEntrega ? user.cidadeEntrega : user.cidade;
      let citiesData = await this.utilsService.getCities(estadoFinal);
      (citiesData as any).filter((item: any) => this.utilsService.replaceSpecialChars(item.nome).toLocaleLowerCase() === this.utilsService.replaceSpecialChars(cidadeFinal).toLocaleLowerCase()).map((item: any) => {
        user.codIbge = item.id;
      });
      if (!user.codIbge && showError) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Atenção',
          detail: 'Cidade/Estado não encontrado. Verifique se os dados estão corretos.',
          life: 3000
        });
        throw new Error('Cidade/Estado não encontrado. Verifique se os dados estão corretos.');
      }
      
    }
    if (user.cepEntrega && !user.codIbgeEntrega) {
      let citiesData = await this.utilsService.getCities(user.estadoEntrega);
      (citiesData as any).filter((item: any) => this.utilsService.replaceSpecialChars(item.nome).toLocaleLowerCase() === this.utilsService.replaceSpecialChars(user.cidadeEntrega).toLocaleLowerCase()).map((item: any) => {
        user.codIbgeEntrega = item.id;
      });
      if (!user.codIbgeEntrega) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Atenção',
          detail: 'Cidade/Estado de Entrega não encontrado. Verifique se os dados estão corretos.',
          life: 3000
        });
        throw new Error('Cidade/Estado de Entrega não encontrado. Verifique se os dados estão corretos.');
      }
    }
  }
}
